import FileReference from './FileReference';
import FileStorageService from './FileStorageService';
import MicrosoftOneDriveInfo from './MicrosoftOneDriveInfo';
import { getArrayOfObjectsFromDto, getObjectFromDto as getObject, getValueFromDto as getValue, getArrayFromDto as getArray, getDateFromDto } from './_helper';

export default class Settings {
	constructor(dto) {
		if (dto instanceof Settings) {
			const oldDto = dto;
			dto = JSON.parse(JSON.stringify(dto));

			if (oldDto.logoImage) { dto.logoImage = new FileReference(oldDto.logoImage) }
			if (Array.isArray(oldDto.contractLogos)) { dto.contractLogos = oldDto.contractLogos.map(x => new FileReference(x)); }
			if (oldDto.logoDarkImage) { dto.logoDarkImage = new FileReference(oldDto.logoDarkImage) }
			if (oldDto.contractWarrantyImage) { dto.contractWarrantyImage = new FileReference(oldDto.contractWarrantyImage) }
		}

		this.timeZone = getValue(dto, 'timeZone', 'string', '');
		this.companyName = getValue(dto, 'companyName', 'string', '');
		this.companyWebsiteUrl = getValue(dto, 'companyWebsiteUrl', 'string', '');
		this.addressStreet = getValue(dto, 'addressStreet', 'string', '');
		this.addressCountry = getValue(dto, 'addressCountry', 'string', '');
		this.addressCity = getValue(dto, 'addressCity', 'string', '');
		this.addressState = getValue(dto, 'addressState', 'string', '');
		this.addressPostalCode = getValue(dto, 'addressPostalCode', 'string', '');
		this.phoneNumber = getValue(dto, 'phoneNumber', 'string', '');
		this.faxNumber = getValue(dto, 'faxNumber', 'string', '');
		this.salespersonTitle = getValue(dto, 'salespersonTitle', 'string', '');
		this.license = getValue(dto, 'license', 'string', '');
		this.themeColor = getValue(dto, 'themeColor', 'string', '#2ab0da');
		this.fromEmailAddress = getValue(dto, 'fromEmailAddress', 'string', '');
		this.replyToEmailAddress = getValue(dto, 'replyToEmailAddress', 'string', '');
		this.smtpHost = getValue(dto, 'smtpHost', 'string', '');
		this.smtpPort = getValue(dto, 'smtpPort', 'number', 0);
		this.smtpUserName = getValue(dto, 'smtpUserName', 'string', '');
		this.smtpPassword = getValue(dto, 'smtpPassword', 'string', '');
		this.fileStorageService = getValue(dto, 'fileStorageService', 'number', FileStorageService.none);
		this.estimateFieldsSpreadsheet = getValue(dto, 'estimateFieldsSpreadsheet', 'string', '');
		this.pdfFallbackFolder = getValue(dto, 'pdfFallbackFolder', 'string', '');
		this.projectTemplateFolder = getValue(dto, 'projectTemplateFolder', 'string', '');
		this.projectContractsTemplateFolder = getValue(dto, 'projectContractsTemplateFolder', 'string', '');
		this.projectWorkflowTemplateFolder = getValue(dto, 'projectWorkflowTemplateFolder', 'string', '');
		this.salespersonFallbackFolder = getValue(dto, 'salespersonFallbackFolder', 'string', '');
		this.microsoftOneDriveInfo = getObject(dto, 'microsoftOneDriveInfo', MicrosoftOneDriveInfo);
		this.hoverIntegrationEnabled = getValue(dto, 'hoverIntegrationEnabled', 'boolean', false);
		this.zohoIntegrationEnabled = getValue(dto, 'zohoIntegrationEnabled', 'boolean', false);
		this.zohoOrganizationDomainName = getValue(dto, 'zohoOrganizationDomainName', 'string', '');
		this.zohoMarketLocation = getValue(dto, 'zohoMarketLocation', 'string', '');
 		this.leadProjectTypesToFolderNameMapping = getValue(dto, 'leadProjectTypesToFolderNameMapping', 'object', {});
		this.projectProjectTypeToFolderNameMapping = getValue(dto, 'projectProjectTypeToFolderNameMapping', 'object', {});
		this.workflowToLeadProjectTypesMapping = getValue(dto, 'workflowToLeadProjectTypesMapping', 'object', {});
		this.logoImage = getObject(dto, 'logoImage', FileReference);
		this.contractLogos = getArrayOfObjectsFromDto(dto, 'contractLogos', FileReference);
		this.logoDarkImage = getObject(dto, 'logoDarkImage', FileReference);
		this.contractAttachments = getArray(dto, 'contractAttachments', []);
		this.contractRoofingScopeOfWork = getValue(dto, 'contractRoofingScopeOfWork', 'string', '');
		this.contractSidingScopeOfWork = getValue(dto, 'contractSidingScopeOfWork', 'string', '');
		this.contractWindowsScopeOfWork = getValue(dto, 'contractWindowsScopeOfWork', 'string', '');
		this.contractWindowDetailsScopeOfWork = getValue(dto, 'contractWindowDetailsScopeOfWork', 'string', '');
		this.contractGuttersScopeOfWork = getValue(dto, 'contractGuttersScopeOfWork', 'string', '');
		this.contractTrimScopeOfWork = getValue(dto, 'contractTrimScopeOfWork', 'string', '');
		this.contractRepairScopeOfWork = getValue(dto, 'contractRepairScopeOfWork', 'string', '');
		this.contractInsulationScopeOfWork = getValue(dto, 'contractInsulationScopeOfWork', 'string', '');
		this.changeOrderScopeOfWork = getValue(dto, 'changeOrderScopeOfWork', 'string', '');
		this.contractWarrantyText = getValue(dto, 'contractWarrantyText', 'string', '');
		this.contractWarrantyImage = getObject(dto, 'contractWarrantyImage', FileReference);
		this.contractCashDiscountPercent = getValue(dto, 'contractCashDiscountPercent', 'number', 0);
		this.contractFinancingApr = getValue(dto, 'contractFinancingApr', 'number', 0);
		this.contractPaymentDisclaimer = getValue(dto, 'contractPaymentDisclaimer', 'string', '');
		this.changeOrderPaymentDisclaimer = getValue(dto, 'changeOrderPaymentDisclaimer', 'string', '');
		this.contractProjectStartDateDisclaimer = getValue(dto, 'contractProjectStartDateDisclaimer', 'string', '');
		this.contractLegalText = getValue(dto, 'contractLegalText', 'string', '');
		this.contractDisclosures = getValue(dto, 'contractDisclosures', 'string', '');
		this.contractAcknowledgements = getValue(dto, 'contractAcknowledgements', 'string', '');
		this.contractPackageLabel1 = getValue(dto, 'contractPackageLabel1', 'string', '');
		this.contractPackageLabel2 = getValue(dto, 'contractPackageLabel2', 'string', '');
		this.contractPackageLabel3 = getValue(dto, 'contractPackageLabel3', 'string', '');
		this.contractEmailMessage = getValue(dto, 'contractEmailMessage', 'string', '');
		this.showDiscountsOnContract = getValue(dto, 'showDiscountsOnContract', 'boolean', true);
		this.showPaymentOptionsOnContract = getValue(dto, 'showPaymentOptionsOnContract', 'boolean', true);
		this.usageReportRecipients = getValue(dto, 'usageReportRecipients', 'object', []);
		this.usageReportFrequency = getValue(dto, 'usageReportFrequency', 'number', 0);
		this.usageReportStartDate = getDateFromDto(dto, 'usageReportStartDate', '');
		this.sectionsInfo = getValue(dto, 'sectionsInfo', 'object', {});
		this.showMeasurmentPopulate = getValue(dto, 'showMeasurmentPopulate', 'boolean', false);
		this.paymentOptions = getValue(dto, 'paymentOptions', 'object', []);
		this.lifetimeWorkmanship = getValue(dto, 'lifetimeWorkmanship', 'boolean', true);
		this.cashCheckDiscount = getValue(dto, 'cashCheckDiscount', 'number', 0);
		this.zeroInterest = getValue(dto, 'zeroInterest', 'object', {});
		this.reducedRate = getValue(dto, 'reducedRate', 'object', {});
		this.showPromotionsOnContract = getValue(dto, 'showPromotionsOnContract', 'boolean', false);

		// readonly
		this.appCompanyName = getValue(dto, 'appCompanyName', 'string', '');
	}
}
